/**
 * 解答情報の共通型定義
 */
import { Vue } from 'vue-property-decorator'

export namespace Question {
  // 解答タイプ
  export const ANSWER_FORMAT_CODE = {
    INPUT: 'INPUT',
    SELF_SCORING: 'SELF_SCORING',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    MULTI_CHOICE: 'MULTI_CHOICE',
    CHANGE_ORDER: 'CHANGE_ORDER',
  }

  /**
   * 選択肢情報を保持する基底クラス
   *   このクラスを継承し、情報を保持する
   */
  class ContentDefault {
    // フロント使用する形に合わせて値を返す
    public get frontValues(): any {
      return {}
    }
  }

  // 解答情報-入力式
  export class ContentInput extends ContentDefault {
    beforeText: string
    correctInputText: string
    afterText: string

    constructor(value?: any) {
      super()
      this.beforeText = value?.beforeText || ''
      this.correctInputText = value?.correctInputText || ''
      this.afterText = value?.afterText || ''
    }

    // フロント使用する形に合わせて値を返す
    public get frontValues(): any {
      return {
        beforeText: this.beforeText,
        afterText: this.afterText,
      }
    }
  }

  // 解答情報-自己採点
  //   現状では値を保持しないため定義なし
  export class ContentSelfScoring extends ContentDefault {
    // eslint-disable-next-line
    constructor(value?: any) {
      super()
    }
  }

  // 解答情報-択一選択
  export class ContentSingleChoice extends ContentDefault {
    labelText: string
    choiceText: string
    isCorrectChoice: boolean

    constructor(value?: any) {
      super()
      this.labelText = value?.labelText || ''
      this.choiceText = value?.choiceText || ''
      this.isCorrectChoice = value?.isCorrectChoice || false
    }

    // フロント使用する形に合わせて値を返す
    public get frontValues(): any {
      return {
        key: this.choiceText,
        label: this.choiceText,
      }
    }
  }

  // 解答情報-複数選択
  export class ContentMultiChoice extends ContentDefault {
    labelText: string
    choiceText: string
    isCorrectChoice: boolean

    constructor(value?: any) {
      super()
      this.labelText = value?.labelText || ''
      this.choiceText = value?.choiceText || ''
      this.isCorrectChoice = value?.isCorrectChoice || false
    }

    // フロント使用する形に合わせて値を返す
    public get frontValues(): any {
      return {
        key: this.choiceText,
        label: this.choiceText,
      }
    }
  }

  // 解答情報-並び替え
  //  入力の関係から、ソート順は数値ではなく文字列型で定義する
  export class ContentChangeOrder extends ContentDefault {
    labelText: string
    choiceText: string
    correctSort: string

    constructor(value?: any) {
      super()
      this.labelText = value?.labelText || ''
      this.choiceText = value?.choiceText || ''
      this.correctSort = value?.correctSort ? String(value?.correctSort) : ''
    }

    // フロント使用する形に合わせて値を返す
    public get frontValues(): any {
      return {
        key: this.choiceText,
        label: this.choiceText,
      }
    }
  }

  /**
   * 設問情報を保持するクラス
   */
  export class Information {
    questionCode: string
    managementLabel: string | null
    answerFormatCode: string
    contents: ContentDefault[]
    // 入力値を保持する変数
    inputValue: any

    constructor(value: any) {
      this.questionCode = value.questionCode
      this.managementLabel = value.managementLabel
      this.answerFormatCode = value.contents[0].answerFormatCode || ANSWER_FORMAT_CODE.SELF_SCORING
      this.contents = []
      for (let i = 0; i < value.contents.length; i++) {
        this.contents.push(Information.buildContentClass(value.contents[i]))
      }
      this.setInputValueDefault()
    }

    /**
     * APIレスポンスからクラスを生成
     *
     * @param content APIから取得した解答情報
     * @returns 解答タイプに応じたクラス
     */
    private static buildContentClass(content: any): ContentDefault {
      // 解答タイプに応じたオブジェクトを返却
      switch (content.answerFormatCode) {
        case ANSWER_FORMAT_CODE.INPUT:
          return new ContentInput(content)
        case ANSWER_FORMAT_CODE.SELF_SCORING:
          return new ContentSelfScoring(content)
        case ANSWER_FORMAT_CODE.SINGLE_CHOICE:
          return new ContentSingleChoice(content)
        case ANSWER_FORMAT_CODE.MULTI_CHOICE:
          return new ContentMultiChoice(content)
        case ANSWER_FORMAT_CODE.CHANGE_ORDER:
          return new ContentChangeOrder(content)
      }

      Vue.prototype.$logger.error('未定義の解答タイプ')
      // 一致しない場合はひとまず自己採点として扱う
      return new ContentSelfScoring(content)
    }

    /**
     * 解答情報を画面表示用にフォーマットする
     *
     * @param answer 解答情報
     * @param answerFormatCode 解答タイプ
     * @returns 表示用文字列(配列)
     */
    public static formatText(answer: any | null, answerFormatCode: string): string[] {
      // 空(解答未入力)の場合はハイフンに変換
      if (answer === null || answer === '') {
        return ['-']
      }
      // 単項目の場合はそのまま返す
      if (!Array.isArray(answer)) {
        return [answer]
      }

      // 複数項目で未選択の場合はハイフンに変換
      if (answer.length == 0) {
        return ['-']
      }

      // 配列の場合は解答タイプによって変換
      switch (answerFormatCode) {
        case ANSWER_FORMAT_CODE.MULTI_CHOICE:
          // 複数選択の場合は／区切りの文字列に変換
          return [answer.join('／')]
        case ANSWER_FORMAT_CODE.CHANGE_ORDER:
          // 並べ替えの場合は→区切りの文字列に変換
          return [answer.join(' → ')]
        case ANSWER_FORMAT_CODE.INPUT:
          // 入力の場合は配列のまま
          return answer.map((input) => {
            // 空文字列はハイフンに置換
            return input === '' ? '-' : input
          })
      }

      // 解答タイプと一致しない場合はそのまま返す
      return answer
    }

    /**
     * フロント使用する形でオブジェクトを返す
     */
    public get frontContents(): any[] {
      const items: any = []
      this.contents.forEach((c) => {
        items.push(c.frontValues)
      })
      return items
    }

    /**
     * 解答入力済みかを判定して返す
     */
    public get isSolved(): boolean {
      // 自己採点モードの場合は必ず「未入力」とする
      if (this.answerFormatCode === ANSWER_FORMAT_CODE.SELF_SCORING) {
        return false
      }

      // 単項目かつ空文字列の場合は「未入力」
      if (this.inputValue === '') {
        return false
      }

      // 単項目であれば「入力済み」と判定しチェック終了
      if (!Array.isArray(this.inputValue)) {
        return true
      }

      // 複数項目かつ配列長が0なら「未入力」
      if (this.inputValue.length === 0) {
        return false
      }

      // 解答形式が入力の場合、全項目をチェック
      if (this.answerFormatCode === ANSWER_FORMAT_CODE.INPUT) {
        // 空文字列が存在するかを判定
        const hasBlank = this.inputValue.some((val) => {
          return val == ''
        })
        // 空文字列が含まれる場合は「未入力」
        if (hasBlank) {
          return false
        }
      }

      return true
    }

    /**
     * 入力値が安全かを検証して設定する
     *    Cookieからの復元時、解答タイプ・問題情報との整合性を確認し、不一致の場合は無視させる
     *
     * @param storedInput 保持されている入力値
     */
    public setSafeInputValue(storedInput: any) {
      // 解答タイプに応じてinputValueに空オブジェクトを設定
      Vue.prototype.$logger.log(`* setSafeInputValueInput-start: ${this.answerFormatCode}`)
      switch (this.answerFormatCode) {
        case ANSWER_FORMAT_CODE.SELF_SCORING:
          this.setSafeInputValueSelfScoring(storedInput)
          break
        case ANSWER_FORMAT_CODE.INPUT:
          this.setSafeInputValueInput(storedInput)
          break
        case ANSWER_FORMAT_CODE.SINGLE_CHOICE:
          this.setSafeInputValueSingleChoice(storedInput)
          break
        case ANSWER_FORMAT_CODE.MULTI_CHOICE:
          this.setSafeInputValueMultiChoice(storedInput)
          break
        case ANSWER_FORMAT_CODE.CHANGE_ORDER:
          this.setSafeInputValueChangeOrder(storedInput)
          break
      }
      Vue.prototype.$logger.log('* setSafeInputValueInput-finished')
    }

    /**
     * 入力値の復元 - 記述式
     *
     * @param storedInput 保持されている入力値
     */
    private setSafeInputValueInput(storedInput: any) {
      // 配列でなければ不一致
      if (!Array.isArray(storedInput)) {
        Vue.prototype.$logger.log('restore-input: not array.')
        return
      }
      // 配列長が異なる場合は不一致
      if (storedInput.length != this.inputValue.length) {
        Vue.prototype.$logger.log('restore-input: length incorrect.')
        return
      }
      // 値の復元
      for (let i = 0; i < this.inputValue.length; i++) {
        if (storedInput[i]) {
          this.inputValue[i] = String(storedInput[i])
        }
      }
    }

    /**
     * 入力値の復元 - 択一式
     *
     * @param storedInput 保持されている入力値
     */
    private setSafeInputValueSelfScoring(storedInput: any) {
      // 配列の場合は不一致
      if (Array.isArray(storedInput)) {
        return
      }
      // console.log('aaaa', storedInput)

      // 存在する選択肢と一致する場合のみ復元
      this.frontContents.forEach((c) => {
        if (c.key == storedInput) {
          this.inputValue = String(storedInput)
        }
      })
    }
    /**
     * 入力値の復元 - 択一式
     *
     * @param storedInput 保持されている入力値
     */
    private setSafeInputValueSingleChoice(storedInput: any) {
      // 配列の場合は不一致
      if (Array.isArray(storedInput)) {
        Vue.prototype.$logger.log('restore-singlechoice: incorrect array.')
        return
      }

      // 存在する選択肢と一致する場合のみ復元
      this.frontContents.forEach((c) => {
        if (c.key == storedInput) {
          this.inputValue = String(storedInput)
        }
      })
    }

    /**
     * 入力値の復元 - 複数選択
     *
     * @param storedInput 保持されている入力値
     */
    private setSafeInputValueMultiChoice(storedInput: any) {
      // 配列でなければ不一致
      if (!Array.isArray(storedInput)) {
        Vue.prototype.$logger.log('restore-multichoice: not array.')
        return
      }

      const restoreValues: any[] = []

      // 存在する選択肢と一致する場合のみ復元
      this.frontContents.forEach((c) => {
        if (storedInput.includes(c.key)) {
          restoreValues.push(c.key)
        }
      })

      // 一致する選択肢が存在した場合のみ復元を実行
      if (restoreValues.length > 0) {
        this.inputValue = restoreValues
      }
    }

    /**
     * 入力値の復元 - 順序指定
     *
     * @param storedInput 保持されている入力値
     */
    private setSafeInputValueChangeOrder(storedInput: any) {
      // 配列でなければ不一致
      if (!Array.isArray(storedInput)) {
        Vue.prototype.$logger.log('restore-changeorder: not array.')
        return
      }

      // 復元可能な値のみ抽出する
      const restorebleValues: any[] = []

      // 存在する選択肢と一致する場合のみ復元
      this.frontContents.forEach((c) => {
        if (storedInput.includes(c.key)) {
          restorebleValues.push(c.key)
        }
      })

      // 一致する選択肢が存在しないばあい終了
      if (restorebleValues.length === 0) {
        Vue.prototype.$logger.log('restore-changeorder: value incorrected.')
        return
      }

      // 順序を合わせて復元
      const restore: any[] = []
      storedInput.forEach((ipt) => {
        if (restorebleValues.includes(ipt)) {
          restore.push(ipt)
        }
      })
      this.inputValue = restore
    }

    /**
     * 解答タイプに応じてinputValueに空オブジェクトを設定する
     */
    private setInputValueDefault() {
      switch (this.answerFormatCode) {
        case ANSWER_FORMAT_CODE.INPUT:
          this.inputValue = []
          for (let i = 0; i < this.contents.length; i++) {
            this.inputValue.push('')
          }
          break
        case ANSWER_FORMAT_CODE.SELF_SCORING:
          this.inputValue = ''
          break
        case ANSWER_FORMAT_CODE.SINGLE_CHOICE:
          this.inputValue = ''
          break
        case ANSWER_FORMAT_CODE.MULTI_CHOICE:
          this.inputValue = []
          break
        case ANSWER_FORMAT_CODE.CHANGE_ORDER:
          this.inputValue = []
          break
        default:
          Vue.prototype.$logger.error('未定義の解答タイプ')
          // 一致しない場合はひとまず自己採点として扱う
          this.inputValue = ''
          break
      }
    }
  }
}
