// import katex from 'katex'

export default class Math {
  contentType: string
  accept: string
  useSolver: boolean

  constructor(opt: { solver?: boolean | null } = {}) {
    this.contentType = 'Math'
    this.accept = 'application/json, application/x-latex'
    this.useSolver = opt.solver || false
  }

  get configuration(): { math: { mimeTypes: string[]; solver: { enable: boolean } } } {
    return {
      math: {
        mimeTypes: ['application/x-latex'],
        solver: {
          enable: this.useSolver,
        },
      },
    }
  }

  // convertHTML(latex: string): string {
  //   return typeof latex === 'string' ? katex.renderToString(latex) : latex
  // }
}
