





import { Component, Vue, Prop } from 'vue-property-decorator'
import DrillTitle from '@/components/modules/drillsv3/atoms/Title.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'

@Component({ components: { DrillTitle, DrillPen } })
export default class TitleWithPenSwitcher extends Vue {
  @Prop({ default: false })
  isHiddenDrillPen?: boolean

  private is_random_question = false
  public async mounted(): Promise<void> {
    this.is_random_question = this.$store.getters['drillsV3/modeActive'].is_random_question
  }
}
