












































import { Component, Vue, Prop } from 'vue-property-decorator'
import RadioImaged from '@/components/atoms/RadioImaged.vue'

const COLOR_BLACK = '#333333'
const COLOR_RED = '#DC361B'
const COLOR_HIGHLIGHT = '#EC8B4C'
@Component({
  components: {
    RadioImaged,
  },
})
export default class DrillPen extends Vue {
  private isDrawable = false
  private selectedValue = ''

  @Prop({ default: { black: false, red: false, hightlight: false } })
  isHiddenPen?: { black: boolean; red: boolean; hightlight: boolean }

  @Prop({
    default: {
      black: { height: '32px', width: '36px' },
      red: { height: '32px', width: '36px' },
      hightlight: { height: '32px', width: '36px' },
      eraser: { height: '32px', width: '36px' },
    },
  })
  widthHeight?: {
    black: { height: string; width: string }
    red: { height: string; width: string }
    hightlight: { height: string; width: string },
    eraser: { height: string; width: string },
  }

  private get canvases() {
    return Vue.prototype.$penCanvases
  }

  private get getWidthHeightProps() {
    return {
      black: { height: '32px', width: '36px' },
      red: { height: '32px', width: '36px' },
      hightlight: { height: '32px', width: '36px' },
      eraser: { height: '32px', width: '36px' },
      ...(this.widthHeight || {})
    }
  }

  private get canvaseKeys() {
    return Object.keys(this.canvases)
  }

  private changePen(e: Event): void {
    const target: any = e.target
    const val = target.value
    const elements = document.getElementsByName('drill__pen')
    if (this.selectedValue === '' || this.selectedValue !== val) {
      this.selectedValue = val
      this.setPenColor()
    } else {
      // 選択済みの場合は未選択にする
      Array.prototype.slice.call(elements).forEach((el) => {
        if (el.value === this.selectedValue) {
          el.checked = false
          this.selectedValue = ''
        }
      })
      this.canvaseKeys.forEach((name: any) => this.canvases[name].deactivateCanvas())
    }
    return
  }

  private setPenColor(): void {
    this.canvaseKeys.forEach((name: any) => this.canvases[name].activateCanvas())

    if (this.selectedValue === 'eraser') {
      this.canvaseKeys.forEach((name: any) => this.canvases[name].setEraser())
      return
    }

    let colorCode = ''
    let alpha = 1
    switch (this.selectedValue) {
      case 'black':
        colorCode = COLOR_BLACK
        break
      case 'red':
        colorCode = COLOR_RED
        break
      case 'highlight':
        colorCode = COLOR_HIGHLIGHT
        alpha = 0.01
        break
    }
    this.canvaseKeys.forEach((name: any) => this.canvases[name].changePenType({ colorCode, alpha }))
  }
}
