




import { Component, Prop, Vue } from 'vue-property-decorator'
import { Sketch } from 'vue-color'
import { Debounce } from 'vue-debounce-decorator'

@Component({
  components: {
    Sketch,
  },
})
export default class ColorPicker extends Vue {
  @Prop()
  onChangeColor!: (value: { hex: string; a: number }) => void

  @Prop()
  color!: string;

  @Prop({default: ''})
  className!: string;

  @Debounce(300)
  private updateValue(colorObj: { hex: string; a: number, hex8: string }) {
    this.onChangeColor?.(colorObj)
  }

}
