


















import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import TooltipButton from '@/components/modules/drills/atoms/TooltipButton.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({
  components: {
    ScrollGuide,
    TooltipButton,
  },
})
export default class QuestionImageHorizontalV3 extends Vue {
  @Prop({ default: { title: '', url: [], questionCode: '', urlD: [] } })
  dataI?: { title: string; url: string[]; questionCode: string; urlD: string[] }

  @Prop()
  sijiUrl?: string

  @Prop()
  answerUrl?: string[]

  @Prop({ default: false })
  isHiddenQuestion?: boolean

  @Prop({ default: false })
  isHiddenAnswer?: boolean

  private get checkDisplaySji() {
    return this.sijiUrl?.length
  }

  private get checkDisplayAnswer() {
    return this.answerUrl?.length
  }
  private get checkDisplayDFrame() {
    return !!this.dataI?.urlD?.length
  }

  private get imageQuestions() {
    return this.dataI?.url
  }

  private get imagesD() {
    return this.dataI?.urlD
  }

  private get checkSubjectCodeKo() {
    return `questions__frameContent ${this.checkDisplayDFrame ? 'questions__frameContent--vertical' : ''}`
  }
}
