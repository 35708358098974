import axios, { AxiosResponse } from 'axios';
import math from './math';
import text from './text';
export type MyScriptStroke = {
  x: number[]
  y: number[]
}
export interface ParamRecognizeText {
  lang?: string
}
export default class MyScript {
  lang: string | null
  strokes: MyScriptStroke[]
  width: number | null
  height: number | null
  content: math | text
  url: string
  response: AxiosResponse | null
  latex: string
  html: string
  mathml2latex: any

  constructor(opt: { lang?: string; strokes?: MyScriptStroke[]; width?: number; height?: number } = {}) {
    this.lang = opt.lang ? opt.lang : null
    this.strokes = opt.strokes ? opt.strokes : []
    this.width = opt.width ? opt.width : null
    this.height = opt.height ? opt.height : null
    this.content = this.defineContent()
    this.url = 'https://cloud.myscript.com/api/v4.0/iink/batch'
    this.response = null
    this.latex = ''
    this.html = ''
    // this.mathml2latex = require('mathml2latex')
  }

  private commonHeader = {
    applicationKey: '7dd74f85-37a4-4533-b2c5-c8f86bbfaae2',
    hmac: '569fa701-3999-4507-894f-ef0f490d78ef'
  }

  get headers() {
    return {
      ...this.commonHeader,
      Accept: this.content.accept,
    }
  }

  get conversionState() {
    return 'DIGITAL_EDIT'
  }

  get body() {
    return {
      configuration: this.content.configuration,
      contentType: this.content.contentType,
      conversionState: this.conversionState,
      strokeGroups: [
        {
          penStyle: null,
          strokes: this.strokes,
        },
      ],
      width: this.width,
      height: this.height,
      xDPI: 96,
      yDPI: 96,
    }
  }

  set setSize(size: { width: number; height: number }) {
    this.width = size.width
    this.height = size.height
  }

  set setStrokes(strokes: MyScriptStroke[]) {
    this.strokes = strokes
  }

  defineContent(): math | text {
    if (this.lang === 'MATHML') return new math()
    return new text({ lang: this.lang })
  }

  async recognize() {
    try {
      this.response = await axios.post(this.url, this.body, { headers: this.headers })
      if (!this.response) return
      // let result = this.response.data.toString()
      // if (this.lang === 'MATHML' && typeof this.response.data === 'number') {
      //   const cloneBody = cloneDeep(this.body)
      //   cloneBody.configuration.math.mimeTypes = ['application/mathml+xml']
      //   const cloneHeader = cloneDeep(this.headers)
      //   cloneHeader.Accept = 'application/json, application/mathml+xml'
      //   const mathmlResponse = await axios.post(this.url, cloneBody, { headers: cloneHeader })
      //   console.log(mathmlResponse, 'mathmlResponse', MathMLToLaTeX.convert(mathmlResponse.data));

      //   result = MathMLToLaTeX.convert(mathmlResponse.data)
      // }
      // this.latex = await this.convertResult(result)
      // this.html = await this.convertHTML()
      return this.response?.data?.toString();
    } catch (err) {
      return ''
    }
  }

  async recognizeText(params: ParamRecognizeText) {
    const textObject = new text({ lang: params.lang || 'ja_JP' })
    const bodyText = {
      configuration: {
       ...textObject.configuration
      },
      contentType: textObject.contentType,
      conversionState: this.conversionState,
      strokeGroups: [
        {
          penStyle: null,
          strokes: this.strokes,
        },
      ],
      xDPI: 96,
      yDPI: 96,
    }
    const headers = {
      ...this.commonHeader,
      Accept: textObject.accept,
    }

    try {
      this.response = await axios.post(this.url, bodyText, { headers })
      if (!this.response) return

      return this.response?.data?.toString();
    } catch (err) {
      return ''
    }
  }

  async convertResult(latex: string): Promise<string> {
    // 9yと書くとqyと変換されるため置換する
    if (latex.indexOf('qy') === 0) latex = latex.replace('q', '9')
    return latex
  }

  // convertHTML(): string {
  //   return this.content.convertHTML(this.latex)
  // }
}
