import { render, staticRenderFns } from "./QuestionImageHorizontalV3.vue?vue&type=template&id=5c2f83ec&scoped=true&lang=pug&"
import script from "./QuestionImageHorizontalV3.vue?vue&type=script&lang=ts&"
export * from "./QuestionImageHorizontalV3.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionImageHorizontalV3.vue?vue&type=style&index=0&id=5c2f83ec&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2f83ec",
  null
  
)

export default component.exports