/**
 * テキスト用のクラス
 */
export default class Text {
  lang: string
  contentType: string
  accept: string

  constructor(opt: { lang?: string | null } = {}) {
    this.lang = opt.lang || 'en_US'
    this.contentType = 'Text'
    this.accept = 'application/json, text/plain'
  }

  get configuration(): {
    text: {
      guides: { enable: boolean }
      smartGuide: boolean
      smartGuideFadeOut: { enable: boolean; duration: number }
      mimeTypes: string[]
    }
    lang: string
  } {
    return {
      text: {
        guides: {
          enable: true,
        },
        smartGuide: true,
        smartGuideFadeOut: {
          enable: false,
          duration: 10000,
        },
        mimeTypes: ['text/plain', 'application/vnd.myscript.jiix'],
      },
      lang: this.lang,
    }
  }

  convertHTML(latex: string): string {
    return latex
  }
}
