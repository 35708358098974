import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DrillCookies extends Vue {
  // ドリルの解答入力情報を保持するcookieキー
  private DRILL_INPUT_COOKIE = 'gdlsDrillInput'

  // ドリルの解答入力情報のcookieキー生成
  private getDrillInputCookieKey(resultDrillId: number): string {
    console.log('getDrillInputCookieKey', `${this.DRILL_INPUT_COOKIE}_${resultDrillId}`)
    return `${this.DRILL_INPUT_COOKIE}_${resultDrillId}`
  }

  /**
   * ドリルの解答入力情報をcookieに保存する
   *
   * @param pageNumber ドリルのページ
   * @param resultDrillId ドリル実施結果ID
   * @param input 入力情報
   */
  protected async setDrillInputCookie(pageNumber: number, resultDrillId: number, input: any[]): Promise<void> {
    // 規定値取得
    const key = this.getDrillInputCookieKey(resultDrillId)
    const cookie = await this.getDrillInputCookie(resultDrillId)

    // 入力情報更新
    cookie[pageNumber] = input

    // cookieの保存(保持期間は専用のものを使用する)
    await Vue.prototype.$cookies.set(
      key,
      JSON.stringify(cookie),
      Function(`return (${process.env.VUE_APP_COOKIE_DRILLINPUT_EXPIRE})`)()
    )
    console.log('setDrillInputCookie', key, JSON.stringify(cookie))
  }

  /**
   * ドリルの解答入力情報をcookieから取得する
   *
   * @param resultDrillId ドリル実施結果ID
   * @returns 入力情報オブジェクト、未保存の場合は空のオブジェクトを返す
   */
  protected async getDrillInputCookie(resultDrillId: number): Promise<any> {
    const key = this.getDrillInputCookieKey(resultDrillId)
    const cookie = await Vue.prototype.$cookies.get(key)

    console.log('getDrillInputCookie', cookie)
    // cookieが存在しない場合は空のオブジェクトを返す
    if (!cookie) {
      return {}
    }

    // cookieが存在する場合は返す
    return cookie
  }

  /**
   * 保持している解答入力情報のページ番号を返す
   *
   * @param resultDrillId ドリル実施結果ID
   * @returns ページ番号(配列)
   */
  protected async getStoredPages(resultDrillId: number): Promise<number[]> {
    const cookie = await this.getDrillInputCookie(resultDrillId)
    const keys = Object.keys(cookie).map((v) => Number(v))

    return keys.sort()
  }

  /**
   * ドリルの解答入力情報をcookieから削除する(ドリル完了時に実行)
   *
   * @param resultDrillId ドリル実施結果ID
   */
  protected async removeDrillInputCookie(resultDrillId: number): Promise<void> {
    const key = this.getDrillInputCookieKey(resultDrillId)
    await Vue.prototype.$cookies.remove(key)
  }

  protected async updateDrillInputCookie(
    resultDrillId: number,
    questionIndex: number,
    value: boolean,
    page: number,
  ): Promise<void> {
    const key = this.getDrillInputCookieKey(resultDrillId)
    const cookie = await Vue.prototype.$cookies.get(key)
    cookie[page][questionIndex] = value
    await Vue.prototype.$cookies.set(
      key,
      JSON.stringify(cookie),
      Function(`return (${process.env.VUE_APP_COOKIE_DRILLINPUT_EXPIRE})`)()
    )
  }
}
