import { Component, Prop, Vue } from 'vue-property-decorator'
import { Question } from '@/models/drillv3/Question'

@Component
export default class QuestionMixin extends Vue {
  protected pageNumber = 1

  @Prop({ default: 0 })
  pageIndex!: number

  // 設問情報
  // ページ-設問の三次元配列
  private questionMap: Question.Information[][] = []

  /**
   * 現在ページの設問情報を取得
   *
   * @returns 設問情報の二次元配列(主に内部使用)
   */
  protected get questionPage(): Question.Information[] | null {
    // 設問情報が未設定の場合はストアから取得
    if (!this.questionMap.length) {
      this.setQuestions()
    }

    return this.questionMap[this.pageIndex] || null
  }

  /**
   * 指定したインデックスの設問情報取得
   *
   * @returns 設問情報
   */
  protected question(questionIndex: number): Question.Information | null {
    // nullガード
    const problem = this.questionPage
    if (!problem) {
      return null
    }

    return problem[questionIndex] || null
  }

  /**
   * ページの入力情報を一括取得
   * @returns 入力情報の配列
   */
  public get getPageInput(): any[] {
    // nullガード
    const questions = this.questionPage
    if (!questions) {
      return []
    }
    const response: any[] = []
    questions.forEach((question) => {
      response.push(question.inputValue)
    })
    return response
  }

  /**
   * store情報からインスタンスを生成
   */
  private setQuestions() {
    const contents = this.$store.getters['drillsV3/questionContents']
    // ページ単位ループ
    for (let pageIndex = 0; pageIndex < contents.length; pageIndex++) {
      // 小問単位ループ
      const tmpPage: Question.Information[] = []
      const problems = contents[pageIndex]
      for (let problemIndex = 0; problemIndex < problems.length; problemIndex++) {
        // 設問単位ループ
        // ページ単位で詰める
        const questions = problems[problemIndex]
        for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
          tmpPage.push(new Question.Information(questions[questionIndex]))
        }
      }
      this.questionMap.push(tmpPage)
    }
  }
}
