




















import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/v3/ModalBase.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'
import { Component, Mixins, Vue, Prop, Ref } from 'vue-property-decorator'
import PenCanvas from '@/components/modules/drills/atoms/PenCanvas.vue'
import { Debounce } from 'vue-debounce-decorator'
import { throttle } from 'lodash'
import DrillPenAi from '@/components/organisms/study/DrillPenAi.vue'

const HEIGHT_PLUS = 1500
const HEIGHT_FIXED_IMAGE = 650
@Component({
  components: {
    ModalBase,
    DrillPen,
    PenCanvas,
    DrillPenAi,
  },
})
export default class ModalMemo extends Mixins(ModalBaseMethod) {
  private title = ''
  private isShowImage = true
  private srcImgArr: string[] = []
  private isShouldPlusHeight = true
  private memoUrl = ''
  private imagedLoad = 0
  private isFixedHeight = false
  @Ref()
  containerMemoRef!: HTMLElement

  @Ref()
  containerImageRef!: HTMLElement

  @Ref()
  containerImageFakeRef!: HTMLElement

  @Prop()
  onSubmit!: (imageFile: File) => void

  @Prop({ default: true })
  isEditMode!: boolean

  @Prop({ default: 'student' })
  idHideScroll!: string

  private canvasNameModalMemo = 'canvasNameModalMemo'
  public showModal(
    memoUrl: string,
    questionUrl: string[],
    isShouldPlusHeight?: boolean,
    other?: {
      isFixedHeight: boolean
    }
  ) {
    this.show()
    this.handleHiddenOrDisplayScrollBelowScreen(true)
    this.isEditMode && window.addEventListener('beforeunload', this.handleBeforeUnload)
    this.isFixedHeight = !!other?.isFixedHeight
    this.isShouldPlusHeight = !!isShouldPlusHeight

    this.srcImgArr = questionUrl || []
    this.memoUrl = memoUrl
    window.addEventListener('resize', this.handleResize)
  }

  @Debounce(300)
  private changeCanvasSize(): void {
    const containerImageFakeEl = this.containerMemoRef.firstChild as any

    const oldCanvasBase64 = Vue.prototype.$penCanvases[this.canvasNameModalMemo].canvasToBase64()
    let realHeight = 0

    if (!this.isFixedHeight) {
      const img = this.containerImageRef.firstChild as any
      const ratio = img.width / img.height

      realHeight = containerImageFakeEl.clientWidth / ratio + HEIGHT_PLUS
    } else {
      realHeight = HEIGHT_FIXED_IMAGE + HEIGHT_PLUS
    }

    containerImageFakeEl.style.height = realHeight + 'px'

    if (containerImageFakeEl) {
      ;(containerImageFakeEl as any).style.display = 'block'
    }

    this.setCanvasSize(
      Vue.prototype.$penCanvases[this.canvasNameModalMemo],
      this.calculateCanvasSize(this.containerMemoRef)
    )

    setTimeout(() => {
      this.drawImageOnCanvas(oldCanvasBase64, false, this.containerMemoRef.scrollWidth)
    })
  }

  private handleLoadImage(event: any) {
    this.imagedLoad = this.imagedLoad + 1
    if (this.imagedLoad < this.srcImgArr.length) {
      return
    }

    const containerImageFakeEl = this.containerImageFakeRef

    let realHeight = 0

    if (!this.isFixedHeight) {
      const img = event.target
      const ratio = img.width / img.height

      realHeight = containerImageFakeEl.clientWidth / ratio + HEIGHT_PLUS
    } else {
      realHeight = HEIGHT_FIXED_IMAGE + HEIGHT_PLUS
    }
    containerImageFakeEl.style.height = realHeight + 'px'

    setTimeout(() => {
      this.drawImageOnCanvas(this.memoUrl, this.isShouldPlusHeight, this.containerMemoRef.scrollWidth)
    })
  }
  private async handleSubmit() {
    if (this.isEditMode) {
      const image = await Vue.prototype.$penCanvases[this.canvasNameModalMemo].canvasToFile(Date.now().toString())
      this.onSubmit?.(image)
      return
    }

    this.cancel()
  }

  public async cancel() {
    this.hide()
    this.srcImgArr = []
    this.isFixedHeight = false
    this.imagedLoad = 0
    this.handleHiddenOrDisplayScrollBelowScreen()
    this.isEditMode && window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  private handleHiddenOrDisplayScrollBelowScreen(hidden?: boolean) {
    const element = document.getElementById(this.idHideScroll)
    if (element) {
      element.style.overflow = hidden ? 'hidden' : 'auto'
    }
  }

  private handleBeforeUnload(event: any) {
    const confirmationMessage = 'メモが保存されていません。メモを続けますか。'
    event.returnValue = confirmationMessage
    return confirmationMessage
  }

  private defaultWidthHeightDrillPen = {
    black: { width: '46px', height: '42px' },
    eraser: { width: '46px', height: '42px' },
  }

  private drawImageOnCanvas(imgUrl: string, isShouldPlusHeight: boolean, widthContent: number) {
    const containerImageFakeEl = this.containerMemoRef.firstChild as any
    const canvas = Vue.prototype.$penCanvases[this.canvasNameModalMemo]
    const context = canvas.context

    if (!imgUrl) {
      this.setCanvasSize(canvas, { width: widthContent, height: containerImageFakeEl.clientHeight })
      if (containerImageFakeEl) {
        ;(containerImageFakeEl as any).style.display = 'none'
      }
      return
    }
    const image = new Image()
    image.src = imgUrl
    Vue.prototype.$loading.start()
    image.onload = () => {
      const ratio = image.width / image.height

      const canvasWidth = widthContent
      const canvasHeight = widthContent / ratio + (isShouldPlusHeight ? HEIGHT_PLUS : 0) // plus height if first create memo
      this.setCanvasSize(canvas, {
        width: canvasWidth,
        height: this.isFixedHeight ? HEIGHT_PLUS + HEIGHT_FIXED_IMAGE : canvasHeight,
      })
      if (containerImageFakeEl) {
        ;(containerImageFakeEl as any).style.display = 'none'
      }

      !isShouldPlusHeight &&
        context.drawImage(
          image,
          0,
          0,
          widthContent,
          this.isFixedHeight ? HEIGHT_PLUS + HEIGHT_FIXED_IMAGE : widthContent / ratio
        )
        Vue.prototype.$loading.complete()
    }
  }

  private get getClassFrameImage() {
    return `modalMemo__content-frameImage${this.isFixedHeight ? ' modalMemo__content-frameImageFixHeight' : ''}`
  }

  private handleResize = throttle(this.changeCanvasSize, 500)

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }

    return { width: element.clientWidth, height: element.scrollHeight }
  }

  private getScrollBarWidth() {
    const scrollDiv = this.containerMemoRef

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

    return scrollbarWidth
  }

  private setCanvasSize(canvas: any, size: { width: number; height: number }): void {
    if (!canvas) return

    canvas.changeSize({
      width: size.width,
      height: size.height,
    })
    canvas.setCanvasContext()
  }

  private beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    window.removeEventListener('resize', this.handleResize)
  }
}
