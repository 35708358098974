


















import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PenCanvas from '@/components/modules/drills/atoms/PenCanvas.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'
import DrillPenAi from '@/components/organisms/study/DrillPenAi.vue'
import { Debounce } from 'vue-debounce-decorator'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {
    ButtonBase,
    PenCanvas,
    DrillPen,
    DrillPenAi,
  },
})
export default class AnswerAreaV3 extends Vue {
  @Prop()
  onSubmit?: () => void

  @Prop()
  onChangeDrawedImage?: (imgUrl: string, width: number) => void

  @Prop({ default: { text: '' } })
  buttonSubmitProp?: { text: string }

  private handleResize = this.changeCanvasSize

  private mounted() {
    Vue.prototype.$logger.info('-- AnswerAreaV3 V3 mounted')
    this.handleResize()

    window.addEventListener('resize', this.handleResize)
  }

  private canvasNameAnswerQuestion = 'canvasNameAnswerQuestion'

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  private handleClickNexPageOrSubmit() {
    this.onSubmit?.()
  }

  @Debounce(300)
  private changeCanvasSize(): void {
    const leftBlock = this.$refs['containerRef'] as HTMLElement
    // canvasサイズ設定
    const { width, height } = this.calculateCanvasSize(leftBlock)
    const imgUrl = Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion].canvasToBase64()

    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion], { width, height })

    setTimeout(() => {
      !!this?.onChangeDrawedImage && this.onChangeDrawedImage(imgUrl, width)
    })
  }

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }
    return { width: element.clientWidth, height: element.scrollHeight }
  }

  private setCanvasSize(canvas: any, size: { width: number; height: number }): void {
    if (!canvas) return

    canvas.changeSize({
      width: size.width,
      height: size.height,
    })
    canvas.setCanvasContext()
  }

  public getImageFileCanvas(imageName: string) {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.exportCanvasToFileImage(imageName)
  }

  public getCanvasImageData() {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.getCanvasAsImageDataType()
  }

  public drawCanvasFromImageData(imageData: string) {
    Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.drawCanvasFromImageData(imageData)
  }

  public clearCanvas() {
    Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.clearCanvas()
  }

  public checkCanvasIsEmpty() {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.checkCanvasIsEmpty()
  }

  public canvasToBase64() {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.canvasToBase64()
  }

  public drawImageFromBase64(base64String: string,
    options: {
      width: number
      height: number
    }) {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]?.drawImageFromBase64(base64String, options)
  }

  public getCurrentCanvas() {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion]
  }

  public getStroke() {
    return Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion].getStroke()
  }


  public clearStrokes() {
    Vue.prototype.$penCanvases[this.canvasNameAnswerQuestion].clearStrokes()
  }
}
