















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RadioImaged extends Vue {
  @Prop()
  width?: string

  @Prop()
  height?: string

  @Prop()
  name!: string

  @Prop()
  value!: boolean

  @Prop()
  id?: string

  @Prop({ default: false })
  checked?: boolean

  @Prop()
  checked_image!: string

  @Prop()
  unchecked_image!: string

  get getId(): string {
    return this.id || `id${this.$uuid.v4()}`
  }

  get setStyleInfo(): { [key: string]: string } {
    return {
      '--width': this.width ? this.width : 'auto',
      '--height': this.height ? this.height : 'auto',
      '--checked_image': `url(${this.checked_image})`,
      '--unchecked_image': `url(${this.unchecked_image})`,
    }
  }
}
