





















import { Component, Vue, Prop } from 'vue-property-decorator'
import RadioImaged from '@/components/atoms/RadioImaged.vue'
import ColorPicker from '@/components/atoms/v3/ColorPicker.vue'
import DrillPenIcon from '@/components/icons/DrillPenIcon.vue'
import DrillPenActiveIcon from '@/components/icons/DrillPenActiveIcon.vue'

const COLOR_BLACK = '#333333'
@Component({
  components: {
    RadioImaged,
    ColorPicker,
    DrillPenIcon,
    DrillPenActiveIcon,
  },
})
export default class DrillPenAi extends Vue {
  @Prop({
    default: {
      black: { height: '32px', width: '36px' },
      eraser: { height: '32px', width: '36px' },
    },
  })
  @Prop()
  nameCanvas!: string

  @Prop()
  idTooltip!: string

  @Prop({ default: {} })
  tooltipColorProp!: Partial<any>

  @Prop()
  widthHeight?: {
    pen: { height: number; width: number }
    eraser: { height: string; width: string }
  }
  private selectedValue = ''
  private color = { hex: COLOR_BLACK, a: 1, hex8: '#333333FF' }
  private alpha = 1
  private active = false
  private activeErase = false
  private show = false
  private get canvases() {
    return Vue.prototype.$penCanvases
  }

  private get getUrlErase() {
    return this.activeErase ? '/img/icon_pen/icon_pen_eraser_on.png' : '/img/icon_pen/icon_pen_eraser_off.png'
  }

  private get canvaseKeys() {
    return Object.keys(this.canvases)
  }

  private handleChangeStatusPen() {
    const canvas = Vue.prototype.$penCanvases[this.nameCanvas]

    
    if (!this.active) {
      canvas.activateCanvas()
      canvas.changePenType({ colorCode: this.color.hex, alpha: this.color.a })
      this.activeErase = false
      this.show = true
    } else  canvas.deactivateCanvas()
    
    this.active = !this.active
  }

  private handleOnOffErase() {
    const canvas = Vue.prototype.$penCanvases[this.nameCanvas]
    if(this.activeErase) {
      canvas.deactivateCanvas()
      this.activeErase = false
      return;
    }

    this.active = false;
    this.activeErase = true;
    canvas.setEraser();
    canvas.activateCanvas()
  }

  private handleChangeColor(color: { hex: string; a: number; hex8: string }) {
    this.color = color
    const canvas = Vue.prototype.$penCanvases[this.nameCanvas]
    canvas.changePenType({ colorCode: color.hex, alpha: color.a })
  }

  private handleClickOutside(event: MouseEvent | TouchEvent) {
    const colorPicker = document.querySelector(`.${this.idTooltip}`);

    if ((this.$refs.container as HTMLElement).contains(event.target as Node) || colorPicker?.contains(event.target as Node)) {
     return;
    }

    if(this.show) {
      this.show = false
     }
  }

  mounted() {
    // Add click event listener to the document
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
    
  }

  beforeDestroy() {
    // Remove click event listener from the document
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }
}
