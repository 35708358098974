import { Component, Mixins, Vue } from 'vue-property-decorator'
import DrillWebApi, { ResultProblem, SProblem } from '@/mixins/drillsv3/WebApi'

@Component
export default class DrillMethod extends Mixins(DrillWebApi) {
  protected sijiUrl = ''

  protected problemsInfo:
    | {
        title: string
        url: string
        questionCode: string
        urlAnswer: string
      }
    | {} = {}

  protected currentQuestion: {
    title: string
    url: string[]
    questionCode: string
    urlAnswer?: string[]
    urlD: string[]
  } = {
    title: '',
    url: [],
    questionCode: '',
    urlAnswer: [],
    urlD: [],
  }

  protected async getImageUrl(
    path: string,
    other?: {
      result_question?: boolean
    }
  ): Promise<string> {
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/imageUrl', {
      params: { path: path, result_question: other?.result_question },
    })
    return result.data.url
  }

  protected async handleGetImageSiji(problems: SProblem[]) {
    const oldSijiUrl = problems?.[0]?.images?.j as string

    if (oldSijiUrl?.length) return this.getImageUrl(oldSijiUrl as string)

    return oldSijiUrl
  }

  private async getUrlOfImg(data: any) {
    const urlArr = Object.values(data)
    const dataUrl = await Promise.all(
      urlArr.map((url) => {
        return Promise.all((url as string[]).map((_url: string) => this.getImageUrl(_url as string)))
      })
    )
    const problemId = Object.keys(data)
    return dataUrl.reduce((newData, url, index) => ({ ...newData, [problemId[index]]: url }), {})
  }

  protected async handleGetImage(problems: SProblem[]) {
    const data = problems.reduce((newData, item) => ({ ...newData, [item.sProblemId]: item.images.m }), {})
    const result = await this.getUrlOfImg(data)
    return result
  }

  protected async handleGetImageD(problems: SProblem[]) {
    const data = problems.reduce((newData, item) => ({ ...newData, [item.sProblemId]: item.images.d }), {})
    const result = await this.getUrlOfImg(data)
    return result
  }

  protected async handleGetImageAnswer(problems: SProblem[]) {
    const data = problems.reduce((newData, item) => ({ ...newData, [item.sProblemId]: item.images.t }), {})
    const result = await this.getUrlOfImg(data)
    return result
  }

  protected async handleGetImageMyAnswer(problems: ResultProblem[], resultDrillId: string, other?: {
    isTeacher?: boolean,
    encodeGdls?: string,
  }) {
    const questionAnswerObj = problems.reduce((newData, item) => {
      const questionResult = item.questions.reduce(
        (_newData, _item) => ({ ..._newData, [_item.questionCode]: _item }),
        {}
      )
      return { ...newData, ...questionResult }
    }, {})

    const questionCodes = Object.keys(questionAnswerObj)
    const questionCodesHaveAnswer = questionCodes.filter(
      (questionCode) => questionAnswerObj[questionCode].userAnswerPath
    )

    const encodeGdls = other?.isTeacher ? other?.encodeGdls : Vue.prototype.$cookies.get('dataGdls').digestGdlsCode || '' 
    const dataUrl = await Promise.all(
      questionCodesHaveAnswer.map((questionCode) =>
        this.getImageUrl(encodeGdls + '/' + resultDrillId+ '/' + (questionAnswerObj?.[questionCode]?.userAnswerPath as string), {
          result_question: true,
        })
      )
    )

    questionCodesHaveAnswer.forEach((questionCode, index) => {
      questionAnswerObj[questionCode].url = dataUrl[index] || ''
    })

    return questionAnswerObj
  }

  protected handleFormatDataProblem(
    problems: SProblem[],
    dataImage: Partial<any>,
    currentPage: number,
    other?: { dataImageAnswer?: Partial<any>; dataImageD: Partial<any>; sijiUrl?: string }
  ) {
    let countQues = 0
    return problems.reduce((newData, item) => {
      countQues++
      if (item.number === 1) {
        return {
          ...newData,
          [item.questions[0].questionCode]: {
            title: currentPage + '-' + countQues,
            url: dataImage?.[item.sProblemId] || [],
            questionCode: item.questions[0].questionCode,
            number: item.number,
            urlAnswer: other?.dataImageAnswer?.[item.sProblemId] || [],
            question_answer: item.images.t?.[0] || '',
            urlD: other?.dataImageD?.[item.sProblemId] || [],
            sijiUrl: other?.sijiUrl,
          },
        }
      }

      const quesData = item.questions.reduce(
        (_newData, _item) => ({
          ..._newData,
          [_item.questionCode]: {
            title: currentPage + '-' + countQues + '~' + currentPage + '-' + (countQues + item.number - 1),
            url: dataImage?.[item.sProblemId],
            questionCode: _item.questionCode,
            number: item.number,
            urlAnswer: other?.dataImageAnswer?.[item.sProblemId] || [],
            question_answer: item.images.t?.[0] || '',
            urlD: other?.dataImageD?.[item.sProblemId] || [],
            sijiUrl: other?.sijiUrl,
          },
        }),
        {}
      )
      countQues = countQues + item.number - 1

      return { ...newData, ...quesData }
    }, {})
  }

  protected async handleGetOneProblemInfo(
    problem: SProblem,
    other: {
      page: number
    }
  ) {
    const [dataImage, dataImageAnswer, sijiUrl, dataImageD] = await Promise.all([
      this.handleGetImage([problem]),
      this.handleGetImageAnswer([problem]),
      this.handleGetImageSiji([problem]),
      this.handleGetImageD([problem]),
    ])

    return this.handleFormatDataProblem([problem], dataImage, other.page, {
      dataImageAnswer,
      dataImageD,
      sijiUrl,
    })
  }
}
