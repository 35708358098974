

























import MyScript, { MyScriptStroke } from '@/components/MyScript'
import ButtonBase from '@/components/atoms/v3/ButtonBase.vue'
import MemoIcon from '@/components/icons/MemoIcon.vue'
import TabsButton from '@/components/modules/drillsv3/molecules/TabsButton.vue'
import TitleWithPenSwitcher from '@/components/modules/drillsv3/molecules/TitleWithPenSwitcher.vue'
import AnswerAreaV3 from '@/components/modules/drillsv3/organisms/AnswerAreaV3.vue'
import ModalMemo from '@/components/modules/drillsv3/organisms/ModalMemo.vue'
import QuestionImageHorizontal from '@/components/modules/drillsv3/organisms/QuestionImageHorizontal.vue'
import QuestionImageHorizontalV3 from '@/components/modules/drillsv3/organisms/QuestionImageHorizontalV3.vue'
import QuestionImageVertical from '@/components/modules/drillsv3/organisms/QuestionImageVertical.vue'
import Transition from '@/components/modules/drillsv3/organisms/Transition.vue'
import DrillMethod from '@/mixins/drillsv3/DrillMethod'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import { ResponseProblem, ResponseStudyProblem, SProblem } from '@/mixins/drillsv3/WebApi'
import { isArray } from 'lodash'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
export type Stroke = {
  x: number
  y: number
}

@Component({
  components: {
    QuestionImageHorizontal,
    QuestionImageVertical,
    Transition,
    TitleWithPenSwitcher,
    AnswerAreaV3,
    QuestionImageHorizontalV3,
    TabsButton,
    ButtonBase,
    ModalMemo,
    MemoIcon,
  },
})
export default class MakeQuestionDrill extends Mixins(DrillTransition, DrillMethod) {
  @Ref() modalMemo!: ModalMemo
  @Ref() tabsButtonRef!: TabsButton
  @Ref() answerAreaRef!: AnswerAreaV3
  @Ref() drillRef!: HTMLElement
  @Prop()
  problems!: ResponseProblem[]
  @Prop()
  studyProblem!: ResponseStudyProblem
  @Prop()
  subjectCode!: string
  @Prop()
  buttonCancelTransition!: { onClick: () => void; text?: string }
  @Prop()
  drillType!: boolean
  @Prop()
  studyType!: string
  private myScript = new MyScript({ lang: 'MATHML' })
  private tabsButton: any[] = []
  private currenTabButtonIndex = 0
  private canvasNotEmpty = {}
  private questionsFile: Partial<any> = {}
  private questionsStrokes: Partial<any> = {}
  private imageDataCanvas = {}

  private wait(ms: number) {
    return new Promise((resolve: any) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  private async recognize(dataStorekes: Stroke[][], type?: 'text', params?: {
    lang?: string
  }) {
    await this.wait(500)

    if (dataStorekes?.length === 0) {
      return ''
    }

    const strokes: MyScriptStroke[] = dataStorekes.map((stroke: Stroke[]) => ({
      x: stroke.map((t: Stroke) => t.x),
      y: stroke.map((t: Stroke) => t.y),
    }))

    this.myScript.setStrokes = await strokes
    this.myScript.setSize = await { width: window.innerWidth, height: window.innerHeight }

    // recognizeRawContent
    if (type === 'text') {
      return this.myScript.recognizeText({ lang: params?.lang })
    }
    return this.myScript.recognize()
  }

  private get bornTextButtonSubmit() {
    if (this.currenTabButtonIndex === this.tabsButton.length - 1) return { text: '正定誤判' }

    return { text: '完了' }
  }

  private handleDoneQuestion() {
    const isEmptyCanvas = this.answerAreaRef.checkCanvasIsEmpty()
    //change tab color
    this.tabsButton = this.tabsButton.map((item, index) =>
      this.currenTabButtonIndex === index
        ? { ...item, ...(isEmptyCanvas ? {} : { color: '#61d84e', colorActive: '#61d84e' }) }
        : item
    )
    //check if in last tab then no action else  go to next tab
    const isLastTab = this.currenTabButtonIndex === this.tabsButton.length - 1
    const indexOfNextTab = this.currenTabButtonIndex + (isLastTab ? 0 : 1)
    this.handleClickTab(
      this.tabsButton[indexOfNextTab],
      (this.tabsButton[this.currenTabButtonIndex] as any).id,
      this.currenTabButtonIndex === this.tabsButton.length - 1
    )
  }

  private handleProblemToTabsButton(problems: SProblem[], currentPage: number) {
    let countQues = 0
    this.tabsButton = problems.reduce((newTabsButton: any[], item) => {
      const listQues = item.questions.map((question) => {
        countQues++
        return {
          id: question.questionCode,
          text: currentPage + '-' + countQues,
          problemId: item.sProblemId,
        }
      })

      return [...newTabsButton, ...listQues]
    }, [])
    this.tabsButtonRef.setCurrentTab(this.tabsButton[0].id)
  }

  private handleClickTab(
    item: { id: string | number; text: string },
    oldItemId: string | number,
    isLastQuestion?: boolean
  ) {
    this.questionsFile = {
      ...this.questionsFile,
      [oldItemId]: this.answerAreaRef.getImageFileCanvas(oldItemId.toString()),
    }
    this.questionsStrokes = {
      ...this.questionsStrokes,
      [oldItemId]: this.answerAreaRef.getStroke(),
    }
    this.canvasNotEmpty[oldItemId] = !this.answerAreaRef.checkCanvasIsEmpty()

    if (isLastQuestion) {
      return
    }
    this.currenTabButtonIndex = this.tabsButton.findIndex((obj: any) => obj.id === item.id)
    this.currentQuestion = this.problemsInfo[item.id]
    this.imageDataCanvas[oldItemId] = this.answerAreaRef.getCanvasImageData()
    this.answerAreaRef.clearCanvas()
    this.answerAreaRef.clearStrokes();
    this.tabsButtonRef.setCurrentTab(item.id)

    this.imageDataCanvas?.[item.id] && this.answerAreaRef.drawCanvasFromImageData(this.imageDataCanvas[item.id])
  }

  private async processData() {
    const newData = {};

    const arrPromise = []
    const arrQuestionBatch = []

    for (const item of this.tabsButton) {
      if (this.subjectCode === 'su' && this.questionsStrokes?.[item.id] && this.questionsStrokes[item.id]?.length) {
        arrPromise.push(Promise.all([this.recognize(this.questionsStrokes[item.id]),
        this.recognize(this.questionsStrokes[item.id], 'text')]))
        arrQuestionBatch.push(item.id)
        continue;
      }

      if (this.subjectCode !== 'su' && this.questionsStrokes?.[item.id] && this.questionsStrokes[item.id]?.length) {
        arrPromise.push(this.recognize(this.questionsStrokes[item.id], 'text', { lang: this.subjectCode === 'ei' ? 'en_US' : undefined }))
        arrQuestionBatch.push(item.id)
        continue;
      }
    }

    const result = await Promise.all(arrPromise)

    arrQuestionBatch.forEach(async (questionCode, index) => {

      if (isArray(result[index])) {
        const [studentTextAnswer, candidatesText] = result[index];
        newData[questionCode] = {
          student_text_answer: studentTextAnswer,
          candidates: (candidatesText || '').replace(/\s/g, '')
        };
      } else {
        newData[questionCode] = {
          student_text_answer: result[index],
        };
      }

    })


    return newData;
  }

  private async handleSubmitAnswer() {
    this.questionsFile = {
      ...this.questionsFile,
      [this.currentQuestion.questionCode]: this.answerAreaRef.getImageFileCanvas(
        this.currentQuestion.questionCode.toString()
      ),
    }
    this.questionsStrokes = {
      ...this.questionsStrokes,
      [this.currentQuestion.questionCode]: this.answerAreaRef.getStroke(),
    }
    const keyQuesCode = Object.keys(this.questionsFile)
    this.canvasNotEmpty[this.currentQuestion.questionCode] = !this.answerAreaRef.checkCanvasIsEmpty()
    const questionCodeEmpty = keyQuesCode.filter((questionCode) => !this.canvasNotEmpty?.[questionCode])

    const tabNameEmpty = this.tabsButton.reduce(
      (newStr, item) => (questionCodeEmpty.includes(item.id) ? [...newStr, item.text] : newStr),
      []
    )

    const tabNameNotSave = this.tabsButton.reduce(
      (newStr, item) =>
        !questionCodeEmpty.includes(item.id) && item.color !== '#61d84e' ? [...newStr, item.text] : newStr,
      []
    )

    let resultConfitm = true

    if (tabNameEmpty.length || tabNameNotSave.length) {
      resultConfitm = confirm(
        `${[
          ...(tabNameEmpty.length ? [tabNameEmpty.join(',') + ' の未解答です'] : []),
          ...(tabNameNotSave.length ? [tabNameNotSave.join(',') + ' が未完了。'] : []),
        ].join(', ')}このまま回答を提供してもよろしいでしょうか。`
      )
    }

    if (!resultConfitm) {
      let indexTab = 0
      let isHaveValue = false
      this.tabsButton = this.tabsButton.map((item, index) => {
        if (questionCodeEmpty.includes(item.id) || (!questionCodeEmpty.includes(item.id) && item.color !== '#61d84e')) {
          if (!isHaveValue) {
            indexTab = index
            isHaveValue = true
          }
          return { ...item, color: '#ffe167', colorActive: '#fbca06' }
        }
        return item
      })

      const isLastTab = indexTab === this.tabsButton.length - 1

      this.handleClickTab(this.tabsButton[indexTab], (this.tabsButton[this.currenTabButtonIndex] as any).id, isLastTab)
      return
    }

    Vue.prototype.$loading.start()

    const dataMyScript = await this.processData()


    const result = await this.saveAllAnswer({
      page_num: this.$route.query.page as string,
      result_drill_id: this.$route.query.resultDrillId as string,
      ocr_data: this.tabsButton.map((item) => ({
        question_code: item.id,
        image: this.questionsFile?.[item.id] || null,
        number: this.problemsInfo[item.id].number.toString(),
        question_answer: this.problemsInfo[item.id].question_answer,
        done: !!this.questionsFile?.[item.id] && !questionCodeEmpty.includes(item.id),
        student_text_answer: dataMyScript?.[item.id]?.student_text_answer || '',
        candidates: dataMyScript?.[item.id]?.candidates || '',
      })) as any,
    })

    if (result?.status === 201) {
      this.$router.push({
        path: this.urlScoring + (this.drillType ? `&questionCode=${this.$route.params.questionCode}&drillType=${this.drillType}` : '') + (this.studyType ? `&studyType=${this.studyType}` : ''),
      })
    }

    Vue.prototype.$loading.complete()
  }

  //modal memo
  private async handleOpenMemo() {
    Vue.prototype.$loading.start()
    const result = await this.getMemoInfo({
      page_num: this.$route.query.page as string,
      result_drill_id: this.$route.query.resultDrillId as string,
      question_code: this.currentQuestion.questionCode,
    })
    Vue.prototype.$loading.complete()

    const imageM = this.subjectCode === 'ko' ? (result.memoPath?.m || []).reverse() : result.memoPath?.m || []
    const imageD = this.subjectCode === 'ko' ? (result.memoPath?.d || []).reverse() : result.memoPath?.d || []

    const imgArr = [...imageM, ...imageD]

    this.modalMemo.showModal(result.url, imgArr, result?.isFirstMemo, {
      isFixedHeight: !!(imgArr.length > 1),
    })
  }

  private async handleSaveMemo(imageFle: File) {
    Vue.prototype.$loading.start()
    await this.saveMemo({
      page_num: this.$route.query.page as string,
      result_drill_id: this.$route.query.resultDrillId as string,
      question_code: this.currentQuestion.questionCode,
      memo_img: imageFle,
    })

    Vue.prototype.$loading.complete()

    this.modalMemo.cancel()
  }

  private get buttonSubmitProps() {
    return {
      class: 'ml_submit-auto',
      onClick: this.handleSubmitAnswer,
    }
  }

  private handleDrawImageWhenResize(urlImg: string, widthContent: number) {
    const img = new Image()
    const canvas = this.answerAreaRef.getCurrentCanvas()
    img.src = urlImg

    img.onload = function () {
      const ratio = img.width / img.height
      canvas?.context.drawImage(img, 0, 0, widthContent, widthContent / ratio)
    }
  }

  @Watch('problems', { deep: true })
  async onProblemsChanged(newProblems: ResponseProblem[]) {
    if (newProblems.length && !!this.studyProblem) {
      this.handleProblemToTabsButton(newProblems[0]?.problems, this.studyProblem.currentPage)

      const [dataImage, sijiUrl, dataImageD] = await Promise.all([
        this.handleGetImage(newProblems[0]?.problems),
        this.handleGetImageSiji(newProblems[0]?.problems),
        this.handleGetImageD(newProblems[0]?.problems),
      ])

      this.sijiUrl = sijiUrl

      const result = this.handleFormatDataProblem(newProblems[0]?.problems, dataImage, this.studyProblem.currentPage, {
        dataImageD,
      })

      this.problemsInfo = result

      this.currentQuestion = Object.values(result)?.[0] as any
    }
  }
}
